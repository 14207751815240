html {
  height: 100vh;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Lato', 'Noto Sans JP', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
}

#root {
  height: 100vh;
  width: 100%;
}

.backyard-top {
  margin-top: 100px;
}

.liff-top {
  height:100vh;
  width: 100%;
  overflow: hidden;
  touch-action: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@page {
  size: 60mm 160mm;
  margin: 0mm;
}

.print-body {
  width: 260px;
  height: 700px;
  page-break-after: always;
}

.text-black {
  color:black;
}

.border-black {
  border-color: black!important;
}

.footer {
  /* position: fixed; */
  position: sticky; /* currently in development for MS Edge */
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  bottom: 0;
  width: 100%;
}

.header {
  top: 0;
  width: 100%;
}

.table-striped-success > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #d2f7f0!important;
}

.table-striped-info > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #cbeaff!important;
}

.btn-submit {
  color: #fff100;
  border-radius: 0;
  border:none;
  background:linear-gradient(#11aa66, #0b8951);
}

.btn-submit-cancel {
  color: #ffffff;
  border-radius: 0;
  border:none;
  background:linear-gradient(#2c2e2d, #1c1e1d);
}

.btn-submit.disabled, .btn-submit:disabled, .btn-submit-cancel.disabled, .btn-submit-cancel:disabled {
  color: #fff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  border: none;
  border-radius: 0;
}

.table-bordered-success {
  border: 1px solid #14977d;
}

.table-bordered-info {
  border: 1px solid #2875a8;
}

.table-bordered-success th, .table-bordered-success td{
  border: 1px solid #14977d;
}

.table-bordered-info th, .table-bordered-info td{
  border: 1px solid #2875a8;
}
.table-bordered-success thead td, .table-bordered-success thead th {
  border-bottom-width: 2px;
}
.table-bordered-info thead td, .table-bordered-info thead th {
  border-bottom-width: 2px;
}

.table-scroll {
  overflow-y: scroll;
}

.w-20 {
  width: 20%!important;
}
.w-10 {
  width: 10%!important;
}

.bg-lightwhite {
  background-color: #e6e6e6 !important;
}

.bg-darkblack {
  background-color: #5e5f5e !important;
}

.bg-black {
  background-color: black !important;
}

.bg-call-head {
  background-color: dodgerblue !important;
  color: white;
}

.bg-call-item {
  background-color: azure !important;
}


.bg-absence-head {
  background-color: royalblue !important;
  color: white;
}

.bg-absence {
  background-color: lightgoldenrodyellow !important;
}

.table-call, .table-absence {
  border-collapse: separate;
  border-spacing: 4px;
}

.table-call > tbody > tr:nth-child(2n+1) > td {
  background-color: white !important;
}
.table-call > tbody > tr:nth-child(2n) > td {
  background-color: white !important;
}

.waiting, .call-head, .absence-head, .call, .absence, .message {
  font-weight: bold;
  white-space: pre;
}

.call {
  font-size: 4.5rem;
}

.section {
  background-color: #11aa66;
  color: white;
  text-align: center!important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.section span {
  color:#fff100;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.section-dark {
  background-color: #5e5f5e;
  color: white;
  text-align: center!important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.section-top {
  height: 2.40625rem;
  overflow: hidden;
}

.border-success {
  border-color: #18bc9c;
}

.border-info {
  border-color: #3498db;
}

.border-darkblack {
  border-color: #5e5f5e!important;
}

.border-success-bold {
  border-color: #18bc9c!important;
  border-width: 2px!important;
}

.border-info-bold {
  border-color: #3498db!important;
  border-width: 2px!important;
}

.border-cell {
  border-color: #c3c3c3!important;
  border-width: 2px!important;
}

.btn-active {
  color: #fff100;
  background:linear-gradient(#11aa66, #0b8951);
  /*border-color: #329763;*/
}

.btn-enable {
  color: #11aa66;
  background:linear-gradient(#ffffff, #ededed);
  /*border-color: #329763;*/
}

.btn-enable:disabled, .btn-enable.disabled, .btn-active:disabled, .btn-active.disabled {
  color: #ffffff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  /*border-color: #329763;*/
}

.btn-active:hover {
  color: #fff100;
}

.btn-enable:hover {
  color: #11aa66;
}

.icon {
  width: 20px;
  height: 20px;
}

.arrow {
  width: 24px;
  height: 24px;
}

/* .csv-input {
  border-left: 12px;
} */

.arrow-lg {
  width: 3rem;
  height: 3rem;
}

.btn, .btn-lg {
  font-weight: bold;
}

.ReactModal__Body--open {
  position: fixed;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.icon-lg {
  width: 60px;
  height: 60px;
}

.btn-submit-circle {
  color: #fff100;
  border-radius: 50%;
  border:none;
  width: 155px;
  height:155px;
  padding: 0px;
  background:linear-gradient(#11aa66, #0b8951);
}

.btn-submit-cancel-circle {
  color: #ffffff;
  border-radius: 50%;
  border:none;
  width: 155px;
  height:155px;
  padding: 0px;
  background:linear-gradient(#2c2e2d, #1c1e1d);
}

.btn-submit-circle.disabled, .btn-submit-circle:disabled, .btn-submit-cancel-circle.disabled, .btn-submit-cancel-circle:disabled {
  color: #fff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  border: none;
  border-radius: 50%;
}

@supports(padding:max(0px)) {
  body, header, footer {
      padding-left: min(0vmin, env(safe-area-inset-left));
      padding-right: min(0vmin, env(safe-area-inset-right));
      padding-top: min(0vmin, env(safe-area-inset-top));
      padding-bottom: min(0vmin, env(safe-area-inset-bottom));
  }
}

@supports(margin:max(0px)) {
  body, header, footer {
    margin-left: min(0vmin, env(safe-area-inset-left));
    margin-right: min(0vmin, env(safe-area-inset-right));
    margin-top: min(0vmin, env(safe-area-inset-top));
    margin-bottom: min(0vmin, env(safe-area-inset-bottom));
  }
}

.line-height-2-2 {
  line-height: 2.2rem;
}

.font-h4 {
  font-size: 1.40625rem;
}

.px-0-env {
  padding-left: calc(0rem + env(safe-area-inset-left))!important;
  padding-right: calc(0rem + env(safe-area-inset-right))!important;
}
.px-3-env {
  padding-left: calc(1rem + env(safe-area-inset-left))!important;
  padding-right: calc(1rem + env(safe-area-inset-right))!important;
}

.p-env-bottom {
  padding-bottom: calc(max(1rem, env(safe-area-inset-bottom)))!important;
}

.page-btn {
  /* margin-top: 2.40625rem; */
  height: calc(100% - 2.40625rem - 2.7578125rem - max(1rem, env(safe-area-inset-bottom)));
  overflow-y: scroll;
  overscroll-behavior: none;
  --webkit-overflow-scrolling: touch;
}
.page-full {
  /* margin-top: 2.40625rem; */
  height: calc(100% - 2.40625rem);
  overflow-y: scroll;
  overscroll-behavior: none;
  --webkit-overflow-scrolling: touch;
}

.bg-line {
  background-color: #20ab3d;
}

.section-line {
  background-color: #20ab3d;
  color: white;
  text-align: center!important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.text-line {
  color:#fff100;
}

.btn-blue-active {
  color: #ffffff;
  background:linear-gradient(#1353df, #1043b1);
  /*border-color: #329763;*/
}

.btn-blue-enable {
  color: #1353df;
  background:linear-gradient(#ffffff, #ededed);
  /*border-color: #329763;*/
}

.btn-blue-enable:disabled, .btn-blue-enable.disabled, .btn-blue-active:disabled, .btn-blue-active.disabled {
  color: #ffffff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  /*border-color: #329763;*/
}

.btn-blue-active:hover {
  color: #ffffff;
}

.btn-blue-enable:hover {
  color: #1353df;
}

.btn-blue-submit {
  color: #ffffff;
  border-radius: 0;
  border:none;
  background:linear-gradient(#1353df, #1043b1);
}

.btn-blue-submit.disabled, .btn-blue-submit:disabled, .btn-blue-submit-cancel.disabled, .btn-blue-submit-cancel:disabled {
  color: #fff;
  background:linear-gradient(#b0b0b0, #9e9e9e);
  border: none;
  border-radius: 0;
}

.blink {
  animation: blinkAnime 0.5s infinite alternate;
}
@keyframes blinkAnime{
   0% { color: #212529; opacity: 0 }
  24% { color: #212529; opacity: 0 }
  25% { color: #212529; opacity: 1 }
 100% { color: #212529; opacity: 1 }
}

.tab-navs {
  overflow-x: scroll;
}

.tab-list {
  width: 90em;
}

.text-font{
  padding: .5rem 1rem;
font-size: 2.4rem;
line-height: 1.2;
border-radius: .3rem;  
font-weight: 500;
}

.section-white {
  background-color: white;
  text-align: center!important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
  padding-right: 0rem;
}

.input-number-120 {
  width: 120px;
}

.calendar_active {
  background-color: rgba(57,88,100,0.25)!important
}